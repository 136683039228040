import React, { useEffect, useRef } from 'react';
import { Box, Card, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Stack } from '@mui/material';
import { useLocation } from '@reach/router';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(() => ({
  mb2: {
    marginBottom: 8,
  },
  fontGilmerBold: {
    fontFamily: 'gilmer-bold',
  },
  fontNarcost: {
    fontFamily: 'narcost-regular',
  },
  fontGilmer: {
    fontFamily: 'gilmer',
  },
  ticketTitle: {
    marginBottom: 8,
    paddingTop: '4%',
  },
  card: {
    borderRadius: '10px',
    border: '1px solid rgba(33, 37, 41, .3)',
    width: '100%',
    paddingTop: 24,
    paddingBottom: 24,
    minWidth: 650,
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: 24,
    left: 64,
  },
}));

const GiftCardTemplate = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redemptionId = params.get('redemption_id');
  const logoUrl = params.get('logo');
  const redemptionTitle = params.get('redemption_title');
  const qrUrl = params.get('qr');
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setTimeout(() => {
      handlePrint();
    }, 2000);
  }, []);

  if (!redemptionId || !logoUrl || !redemptionTitle || !qrUrl) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md" ref={componentRef}>
        <Grid container>
          <Grid
            item
            container
            spacing={2}
            className={classes.ticketTitle}
            sx={{ textAlign: 'center' }}
            md={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Card raised className={classes.card}>
              <Box className={classes.logo}>
                <img width={100} height={40} src={logoUrl} alt="logo" />
              </Box>
              <Grid item md={12}>
                <Stack
                  direction="column"
                  spacing={2}
                  textAlign="center"
                  width="100%"
                  minWidth={650}
                >
                  <Typography
                    variant="h5"
                    className={[classes.mb2, classes.fontGilmerBold]}
                  >
                    {redemptionTitle}
                  </Typography>
                  <Box>
                    <img width={200} src={qrUrl} alt="gift card qr code" />
                  </Box>
                  <Typography
                    variant="h5"
                    className={[classes.mb2, classes.fontGilmerBold]}
                  >
                    {redemptionId}
                  </Typography>
                </Stack>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default GiftCardTemplate;
