import React from 'react';
import { Router } from '@reach/router';
import GiftCardTemplate from '../components/routes/GiftCard/GiftCardTemplate';
import UnsubscribeEmailSuccess from '../components/routes/GiftCard/UnsubscribeEmailSuccess';

const GiftCardPage = () => {
  return (
    <Router basepath="giftcard">
      <GiftCardTemplate path="print" />
      <UnsubscribeEmailSuccess path="unsubscribe-email-success" />
    </Router>
  );
};

export default GiftCardPage;
