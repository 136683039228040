import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import logoUrl from '../../../images/logo.png';

const useStyles = makeStyles(() => ({
  fs14: {
    fontSize: 14,
  },
  mt50: {
    marginTop: 50,
  },
}));

const UnsubscribeEmailSuccess = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="md">
        <Grid container>
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <Box className={classes.mt50}>
              <img width={100} height={40} src={logoUrl} alt="Pintuna logo" />
            </Box>
            <Grid item md={12}>
              <Typography variant="h5" className={classes.fs14}>
                {t('unsubscribeEmailSuccess')}
              </Typography>
              <Typography variant="h5" className={classes.fs14}>
                {t('thank')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UnsubscribeEmailSuccess;
